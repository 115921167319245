.primary-btn {
  background-color: $primary !important;
  width: 300px;
  padding: 20px;
  @media (max-width: $small) {
    width: 100%;
  }
}

.secondary-btn {
  background-color: $primary !important;
}

.btn-outline {
  color: white;

  &:hover {
    background-color: transparent !important;
    opacity: 0.8;
  }
}
