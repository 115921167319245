.packages-container {
  img {
    margin: auto;
    width: 300px;
  }

  .old-price {
    position: relative;
    overflow: hidden;
    display: inline-block;

    &:before {
      content: "";
      border-top: 5px solid #dc3545;
      width: 100%;
      position: absolute;
      transform: rotate(-15deg);
      bottom: 47.5%;
      left: 0;
      border-radius: 15px;
    }
  }
}
